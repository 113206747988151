import React, { useState } from 'react'
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../styles/pay.css'


function Pay() {
    const location = useLocation();
    const formValues = location.state;
    const [qrCode] = useState(formValues.upiurl);
    const [payRef, setPayRef] = useState(formValues.payfef);
    const dateObj = new Date(formValues.date.split('-').reverse().join('-'));

    const handlePayRef = () => {
        if (payRef) {

            axios.post(`${process.env.REACT_APP_GT_API_URL}/gtadmin/bookedslots?orderid=${formValues.orderid}`,
                { "payref": payRef })
                .then(res => {
                    console.log(res.data)
                })
                .catch(error => console.log(error))
        }
    }
    console.log(formValues)

    return (
        <div className='pay'>

            <div className="qr-code">
                <h4>Scan the below QR Code to complete the payment</h4>
                <h6></h6>
                <img src={`data:image/png;base64,${qrCode}`} alt="QR COde" />
                <p>Once the payment is processed successfully, you will receive a notification on your registered mobile number.</p>
                <p>Note: Slot will be booked only after completing the payment</p>
                <br />
                <br />
                <p>Enter the reference number</p>
                <div className="payment-ref">
                    <input type="text" value={payRef} onChange={e => setPayRef(e.target.value)} />
                    <button onClick={handlePayRef}>Confirm</button>
                </div>
            </div>
            <div className="book-info">
                <div className="circle1"></div>
                <div className="circle2"></div>
                <div className="book-date">
                    <span>
                        <h3>{formValues.starttime} - {formValues.endtime}</h3>
                        <h2>{dateObj.toLocaleString('en-us', { weekday: 'long', day: 'numeric', month: 'long' })}</h2>
                    </span>
                    <h1>
                        <span>Order Id: </span>#{formValues.orderid}</h1>
                </div>
                <div className="user-info">
                    <h3><span>Total Price:</span>₹{formValues.totalprice} </h3>
                    <h3><span>Name:</span>{formValues.customer.name} </h3>
                    <h3><span>Phone No:</span>{formValues.customer.phone} </h3>
                </div>
                <div className="more-info">
                    <h3><span>No. of guests:</span>{formValues.guests} </h3>
                    <h3><span>Cake:</span>{formValues.cake ? formValues.cake.name : "No Cake"} </h3>
                    <h3><span>decoration: </span>{formValues.decoration ? formValues.decoration.name : "No decoration"} </h3>
                </div>
            </div>
        </div>
    )
}

export default Pay