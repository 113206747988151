import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import axios from 'axios'
import '../styles/book-slot.css'
import 'react-datepicker/dist/react-datepicker.css'
import GTDatePicker from '../components/DatePicker'
import { Helmet } from "react-helmet"
import miniThetreImage from '.././assets/mini-theatre-theatre.jpg'

function BookSlot() {
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [slots, setSlots] = useState([])
    const [theatreDetails, setTheatreDetails] = useState([])
    const [decorations, setDecorations] = useState([])
    const [cakes, setCakes] = useState([])
    const [errors, setErrors] = useState({})
    const [OTP, setOTP] = useState()
    const [verified, setVerified] = useState(false)
    const [totalprice, settotalprice] = useState("")
    const [time, setTime] = useState(60);
    const [isRunning, setIsRunning] = useState(false);


    const navigate = useNavigate()
    gsap.registerPlugin(ScrollTrigger)
    const slotDateRef = useRef(null)
    const orderDetailsRef = useRef(null)
    const bookSlotRef = useRef(null)
    const pinningAnimationRef = useRef(null)

    const [bookingData, setbookingData] = useState({
        thid: "gt_mini",
        date: "",
        duration: 3,
        starttime: "",
        endtime: "",
        customer: {
            name: "",
            phone: "",
            email: ""
        },
        guests: 2,
        dec_id: "",
        cake_id: ""
    })

    const getTheatreDetails = () => {
        axios.get(`${process.env.REACT_APP_GT_API_URL}/gtadmin/theatredetails`)
            .then(res => {
                setTheatreDetails(res.data)
                settotalprice(res.data.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].base_price)
            })
            .catch(err => console.log(err))
    }

    const getDecorations = () => {
        axios.get(`${process.env.REACT_APP_GT_API_URL}/gtadmin/decorations`)
            .then(res => {
                setDecorations(res.data)
            })
            .catch(err => console.log(err))
    }

    const getCakes = () => {
        axios.get(`${process.env.REACT_APP_GT_API_URL}/gtadmin/cakes`)
            .then(res => {
                setCakes(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        getTheatreDetails()
        getDecorations()
        getCakes()
    }, [])

    useEffect(() => {
        let timerId;

        if (isRunning && time > 0) {
            timerId = setInterval(() => {
                setTime(prevTime => prevTime - 1);
            }, 1000);
        } else {
            clearInterval(timerId);
            setIsRunning(false);
        }

        return () => clearInterval(timerId);
    }, [isRunning, time]);

    useEffect(() => {
        const formattedDate = formatDate(selectedDate)
        setbookingData((prevVal) => ({ ...prevVal, date: formattedDate }))
        getAvailableSlots(formattedDate)
    }, [selectedDate])

    useEffect(() => {
        const formattedDate = formatDate(selectedDate)
        setbookingData((prevVal) => ({ ...prevVal, guests: theatreDetails.length > 0 ? theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].base_persons : 2 }))
        getAvailableSlots(formattedDate)
        if (theatreDetails.length > 0) {
            settotalprice(theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].base_price)
        }
    }, [bookingData.thid, bookingData.duration])

    useEffect(() => {
        if (bookingData.customer.name === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                ['name']: 'Please Enter Your Name'
            }))
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                ['name']: ''
            }))
        }

        if (bookingData.customer.phone === '') {
            setErrors(prevErrors => ({
                ...prevErrors,
                ['phone']: 'Please Enter Your Phone Number'
            }))
        }
    }, [bookingData])

    useEffect(() => {
        const child1 = slotDateRef.current
        const parent = bookSlotRef.current

        if (child1 && parent) {
            const createPinningAnimation = () => {
                const ctx = gsap.context(() => {
                    const pinningAnimation = ScrollTrigger.create({
                        trigger: parent,
                        start: 'top 50px',
                        end: parent.offsetHeight + child1.offsetHeight,
                        pin: child1,
                        pinSpacing: false,
                        scrub: true,
                    })

                    pinningAnimationRef.current = pinningAnimation
                })

                return ctx
            }

            const ctx = gsap.matchMedia().add('(min-width: 1000px)', createPinningAnimation)

            return () => {
                if (pinningAnimationRef.current) {
                    pinningAnimationRef.current.kill()
                }
                ctx.revert()
            }
        }
    }, [])

    const handleStartStop = () => {
        setIsRunning(prevIsRunning => !prevIsRunning);
    };

    const handleReset = () => {
        setTime(0);
        setIsRunning(false);
    };


    const updateguests = (type) => {
        let tempbdata = { ...bookingData }
        let cap = theatreDetails.filter(item => item.thid === bookingData.thid)[0].max_capacity
        if (type === "inc") {
            tempbdata.guests = tempbdata.guests === cap ? cap : tempbdata.guests + 1
        } else {
            tempbdata.guests = tempbdata.guests === 1 ? 1 : tempbdata.guests - 1
        }
        setbookingData(tempbdata)
        updatetotal(tempbdata)
    }

    const handleChange = (e) => {
        let tempbdata = { ...bookingData }
        const { name, value } = e.target
        let error = ''
        const phoneRegex = /^\d{10}$/
        const nameRegex = /^[A-Za-z]+(?:[ -][A-Za-z]+)*$/

        if (name === 'phone') {
            if (phoneRegex.test(value)) {
                error = ''
            } else {
                error = 'Please enter a valid 10-digit phone number.'
            }
        } else if (name === 'name') {
            if (nameRegex.test(value)) {
                error = ''
            } else {
                error = 'Please enter a valid name.'
            }
        }

        setErrors(prevErrors => ({
            ...prevErrors,
            [name]: error
        }))
        tempbdata.customer[name] = value
        setbookingData(tempbdata)
    }

    function formatDate(date) {
        const tempSelectedDate = date ? date : new Date()
        const day = String(tempSelectedDate.getDate()).padStart(2, '0')
        const month = String(tempSelectedDate.getMonth() + 1).padStart(2, '0') // Months are zero-based
        const year = tempSelectedDate.getFullYear()
        return `${day}-${month}-${year}`
    }
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const getAvailableSlots = (date) => {
        setSlots([])
        setErrors(prevErrors => ({
            ...prevErrors,
            ['slot_time']: "Please select a time slot."
        }))
        setbookingData((prevVal) => ({ ...prevVal, slot_name: '' }))
        axios.get(`${process.env.REACT_APP_GT_API_URL}/gtbookings/booking?date=${date}&thid=${bookingData.thid}&duration=${bookingData.duration}`)
            .then(res => {
                setSlots(res.data)
            })
            .catch(err => console.log(err))
    }

    const updatetotal = (bdata) => {
        const theatrepricing = theatreDetails.filter(item => item.thid === bdata.thid)[0].pricing.filter(item => item.duration === bdata.duration)[0]
        let price = theatrepricing.base_price
        if (bdata.guests > theatrepricing.base_persons) {
            price += theatrepricing.addon_price * (bdata.guests - theatrepricing.base_persons)
        }
        price += bdata.dec_id === "" ? 0 : decorations.filter(item => item.dec_id === bdata.dec_id)[0].price
        price += bdata.cake_id === "" ? 0 : cakes.filter(item => item.cake_id === bdata.cake_id)[0].price
        settotalprice(price)
    }

    const updateslot = () => {

    }

    const updateextras = (key, value) => {
        let tempbdata = { ...bookingData }
        if (tempbdata[key] === value) {
            tempbdata[key] = ""
        } else {
            tempbdata[key] = value
        }
        setbookingData(tempbdata)
        updatetotal(tempbdata)
    }

    const isErrorsEmpty = () => {
        return Object.values(errors).every(error => error === '')
    }

    const submitHandler = () => {
        axios.post(`${process.env.REACT_APP_GT_API_URL}/gtbookings/booking`, bookingData)
            .then(res => {
                if (res.data.upiurl) {
                    navigate('/pay', { state: res.data })
                }
            })
            .catch(error => console.log(error))
    }

    const getOTP = () => {
        setErrors(prevErrors => ({
            ...prevErrors,
            ['OTP']: "Enter OTP."
        }))
        axios.post(`${process.env.REACT_APP_GT_API_URL}/gtbookings/getotp `, { "phone": bookingData.customer.phone })
            .then(res => {
                setTime(60)
                setIsRunning(true)
            })
            .catch(error => console.log(error))
    }

    const verifyOTP = () => {
        axios.post(`${process.env.REACT_APP_GT_API_URL}/gtbookings/verify `, { "phone": bookingData.customer.phone, "otp": OTP })
            .then(res => {
                if (res.data.status === 'verified') {
                    setVerified(true)
                    setErrors(prevErrors => ({
                        ...prevErrors,
                        ['OTP']: ""
                    }))
                }

            })
            .catch(error => {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    ['OTP']: "Invalid OTP."
                }))
            })
    }
    const handleTheaterSelect = (thid) => {
        if (theatreDetails) {
            setbookingData((prevVals) => ({ ...prevVals, thid: thid }))
        }
    }

    return (
        <div ref={bookSlotRef} className='book-slot'>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Book Your Event Slot - Best Private Theatres in Kompally | Cafe Game Theory </title>
                <meta name="og:description" content="Game Theory provides top-rated private theatres for celebrations and movie screening in Secunderabad and Kompally. Celebrate birthdays, anniversaries, dates, and proposals in our best-in-class theatres equipped with Dolby Atmos sound, 4k video, and Apple TV. Get ready to experience Game Theory at the most trusted private theatres. Reserve now and create unforgettable moments with your loved ones!" />
            </Helmet>
            <div ref={slotDateRef} className="slot-date">
                <div className='date-picker'>
                    {selectedDate && <GTDatePicker onDateChange={setSelectedDate} selectedDate={selectedDate} />}
                </div>
                <div className="price-box">
                    <div className="theatre-location">
                        <svg width="12" height="17" viewBox="0 0 12 17" fill="none">
                            <path d="M6.22437 0.0829926C3.1189 0.0829926 0.599365 2.48104 0.599365 5.43456C0.599365 8.83299 4.34937 14.2185 5.72632 16.0787C5.78347 16.1572 5.85839 16.2211 5.94494 16.2652C6.0315 16.3092 6.12724 16.3322 6.22437 16.3322C6.32149 16.3322 6.41723 16.3092 6.50379 16.2652C6.59034 16.2211 6.66526 16.1572 6.72241 16.0787C8.09937 14.2193 11.8494 8.83573 11.8494 5.43456C11.8494 2.48104 9.32983 0.0829926 6.22437 0.0829926Z" fill="#E62E28" />
                            <path d="M6.22437 7.58299C7.2599 7.58299 8.09937 6.74353 8.09937 5.70799C8.09937 4.67246 7.2599 3.83299 6.22437 3.83299C5.18883 3.83299 4.34937 4.67246 4.34937 5.70799C4.34937 6.74353 5.18883 7.58299 6.22437 7.58299Z" fill="#732826" />
                        </svg>
                        <a href="https://maps.app.goo.gl/fWu77Scf9NttJTT56"> Kompally</a>
                    </div>
                    <div className="total-price">
                        <p>Total Price:</p>
                        <h2>₹{totalprice}</h2>
                    </div>
                </div>
            </div>
            {theatreDetails && theatreDetails.length !== 0 && <div ref={orderDetailsRef} className="order-details">
                <div className="theatre-select">
                    {theatreDetails && theatreDetails.map((theatre) =>
                        <div onClick={() => handleTheaterSelect(theatre.thid)} key={theatre.thid} className={`theatre ${bookingData.thid === theatre.thid ? 'selected-theatre' : ''}`}>
                            <div className="theatre-image">
                                {/* {theatre.images[0] ? <img src={theatre.images[0]} alt="" /> : <img src={miniThetreImage} alt="" />} */}
                                <a href={theatre.videos[0]}>
                                    <svg width="55" height="55" viewBox="0 0 55 55" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M27.5 55C42.6879 55 55 42.6879 55 27.5C55 12.3121 42.6879 0 27.5 0C12.3121 0 0 12.3121 0 27.5C0 42.6879 12.3121 55 27.5 55ZM23.4141 36.406C23.6638 36.549 23.9465 36.6244 24.2344 36.625C24.4108 36.6243 24.5852 36.5956 24.7512 36.5405C24.8688 36.5016 24.9821 36.4496 25.0889 36.385L36.7087 29.4287C36.9508 29.2769 37.1504 29.066 37.2888 28.816C37.4271 28.5659 37.4998 28.2848 37.4998 27.999C37.4998 27.7133 37.4271 27.4321 37.2888 27.182C37.1952 27.0129 37.0737 26.8618 36.9299 26.7349C36.8611 26.674 36.7871 26.6185 36.7087 26.5693L25.0907 19.615C24.8387 19.4615 24.5502 19.3787 24.2552 19.375C23.9603 19.3713 23.6698 19.4469 23.4141 19.5939C22.8516 19.9126 22.5018 20.5295 22.5018 21.2031V34.7969C22.5018 35.4685 22.8516 36.0873 23.4141 36.406Z" fill="#E62E28" />
                                    </svg>
                                </a>
                            </div>
                            {theatre.pricing.filter(item => item.duration === bookingData.duration).length > 0 ? (
                                theatre.pricing.filter(item => item.duration === bookingData.duration).map((tp) =>
                                    <div className="theatre-info">
                                        <div><h2>{theatre.name}</h2><h3>Price: ₹{tp.base_price}</h3></div>
                                        <p>Persons: {tp.base_persons}</p>
                                        <p>Charge per Extra Person: ₹{tp.addon_price}</p>
                                        <p>Max. Capacity: {theatre.max_capacity} Persons</p>
                                        <p>Specs: {theatre.specs.map((spec, j) =>
                                            <span key={j}>{spec}<br /></span>
                                        )}
                                        </p>
                                    </div>
                                )
                            ) : (
                                <div className="unavailable-overlay">
                                    {theatre.images[0] ? <img src={theatre.images[0]} alt="" /> : <img src={miniThetreImage} alt="" />}
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.1323 6.82291C10.0362 5.97738 10.8999 5.25 12 5.25C13.1001 5.25 13.9638 5.97739 13.8677 6.82291L13.0233 14.25H10.9767L10.1323 6.82291Z" fill="white" />
                                        <path d="M13.5117 17.25C13.5117 18.0784 12.8401 18.75 12.0117 18.75C11.1833 18.75 10.5117 18.0784 10.5117 17.25C10.5117 16.4216 11.1833 15.75 12.0117 15.75C12.8401 15.75 13.5117 16.4216 13.5117 17.25Z" fill="white" />
                                        <path d="M0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12ZM22.05 12C22.05 17.5505 17.5505 22.05 12 22.05C6.44954 22.05 1.95 17.5505 1.95 12C1.95 6.44954 6.44954 1.95 12 1.95C17.5505 1.95 22.05 6.44954 22.05 12Z" fill="white" />
                                    </svg>
                                    <p>{`${theatre.name} is only available on ${theatre.pricing[0].duration} hour(s) duration`}</p>
                                </div>
                            )
                            }
                        </div>
                    )}
                </div>
                <div className="slot-duration-wrapper">
                    <h3>Select Duration</h3>
                    <div className="slot-durations">
                        {theatreDetails && theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.map((durr, i) =>
                            <div key={i} onClick={() => setbookingData((prevVal) => ({ ...prevVal, duration: durr.duration }))} className={`slot-duration ${bookingData.duration === durr.duration && 'selected-slot-duration'}`}>
                                <p>{durr.duration} Hours</p>
                            </div>
                        )}
                    </div>
                </div>
                <div className="slots-times">
                    {selectedDate && <h3><span>Slots available on</span> <br /> {selectedDate.toLocaleString('en-us', {
                        weekday: 'long',
                        day: 'numeric',
                        month: 'long',
                    })}:</h3>}
                    <div className="slots-time-wrapper">
                        {slots.map((slot, index) => {
                            return (
                                <div onClick={() => {
                                    if (slot.isavailable) {
                                        setbookingData((prevVal) => ({ ...prevVal, slot_name: slot.slot_name, starttime: slot.starttime, endtime: slot.endtime }));
                                        setErrors((prevErrors) => ({ ...prevErrors, slot_time: '' }))
                                    }
                                }}
                                    key={index} className={`slot-time ${bookingData.slot_name === slot.slot_name && 'selected-slot-time'} ${!slot.isavailable && 'slot-unavailable'}`}>
                                    <p>{`${slot.starttime} - ${slot.endtime}`}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="guests">
                    <h3>Number of Guests</h3>
                    <div className="guest-counter">
                        <div className="counter-controls">
                            <button className="decrement-btn" onClick={() => updateguests("dec")} disabled={bookingData.guests === 1}>
                                -
                            </button>
                            <div className="guest-count">{bookingData.guests}</div>
                            <button className="increment-btn" onClick={() => updateguests("inc")} disabled={bookingData.guests >= theatreDetails.filter(item => item.thid === bookingData.thid)[0].max_capacity}>
                                +
                            </button>
                        </div>
                    </div>
                    <p>{theatreDetails.filter(item => item.thid === bookingData.thid)[0][bookingData.duration] && `₹${theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].base_price} for ${theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].base_persons} or less people ₹${theatreDetails.filter(item => item.thid === bookingData.thid)[0].pricing.filter(item => item.duration === bookingData.duration)[0].addon_price} Per extra person}`}</p>
                </div>
                <div className="cakes">
                    <h3>Cakes (Optional)</h3>
                    <div className="cake-cards">
                        {cakes && cakes.map((cake, index) =>
                            <div onClick={() => updateextras("cake_id", cake.cake_id)} key={index} className={`cake-card ${bookingData.cake_id === cake.cake_id && 'selected-card'}`}>
                                <h3>{cake.name}</h3>
                                <h4>₹{cake.price}</h4>
                            </div>
                        )}
                    </div>
                </div>
                <div className="decorations">
                    <h3>Decorations (Optional)</h3>
                    <div className="decoration-cards">
                        {decorations && decorations.map((decor, index) =>
                            <div onClick={() => { updateextras("dec_id", decor.dec_id) }} key={index} className={`decoration-card ${bookingData.dec_id === decor.dec_id && 'selected-card'}`}>
                                <h3>{decor.name}</h3>
                                <h4>₹{decor.price}</h4>
                            </div>
                        )}
                    </div>
                </div>
                <div className="user-info">
                    <h3>Name</h3>
                    <input
                        type="text"
                        name="name"
                        className="name-input"
                        value={bookingData.customer.name}
                        onChange={handleChange}
                    />
                    {isRunning && <p style={{ color: 'green' }}>OTP Sent</p>}
                    <h3>Phone Number</h3>
                    <div className="phone-input-wrapper">
                        <input
                            type="tel"
                            name="phone"
                            className="phone-input"
                            value={bookingData.customer.phone}
                            onChange={handleChange}
                        />
                        {isRunning && !verified ? <h3 style={{}}>{formatTime(time)}</h3> : <button onClick={getOTP} disabled={errors.phone || verified} >Get OTP</button>}
                    </div>
                    <h3>Enter OTP</h3>
                    {verified && <p style={{ color: 'green' }}>Number Verified</p>}
                    <div className="phone-input-wrapper">
                        <input
                            disabled={verified}
                            type='tel'
                            name="phone"
                            className="phone-input"
                            value={OTP}
                            onChange={(e) => {
                                setOTP(e.target.value)
                            }}
                        />
                        <button disabled={!OTP || verified} onClick={verifyOTP}>Verify</button>
                    </div>
                    {errors.OTP && <p style={{ color: 'red' }}>{errors.OTP}</p>}
                    <h3>Email (Optional)</h3>
                    <input
                        type="email"
                        name="email"
                        className="email-input"
                        value={bookingData.email}
                        onChange={handleChange}
                    />
                    {/* {errors.slot_time && <p>{errors.slot_time}</p>}
                    {errors.name && <p>{errors.name}</p>}
                    {errors.phone && <p>{errors.phone}</p>} */}
                    {Object.values(errors).every(error => error !== '') && <div className="errors">
                        {Object.values(errors).map((error, index) => <p key={index}>{error}</p>)}
                    </div>}
                    <button onClick={submitHandler} disabled={!isErrorsEmpty() || !verified}>Book Now</button>
                </div>
            </div>}
        </div>
    )
}

export default BookSlot