import React from "react"
import "../styles/footer.css"

const Footer = () => {

    const scrollDown = () => {
        window.scrollTo({
            top: "",
            behavior: 'smooth',
        })
    }

    return (
        <footer>
            <div className="footer">
                <div className="row">
                    <div>
                    <a href="/">
                        <img src={require("../assets/gtfinal.png")} height="120px" width="150px" />
                    </a>
                    <h4>
                        <a className="about-us">Cafe Game Theory - Kompally, Hyderabad</a>
                    </h4>
                    </div>
                    <div>
                    <ul>
                        <li>
                            <a href="contact-us">Contact us</a>
                        </li>
                    </ul>
                    <a href="https://instagram.com/gametheory.cafe"> Follow us on <i className="fa fa-instagram"></i>
                    </a>
                    <br />
                    <br />
                    <label><p className="txt-light">Contact: <a href="tel:+917799420720" >+91 7799 420 720</a></p> </label>
                    </div>
                    <div className="row"> || © 2023 Cafe Game Theory. All rights reserved. || </div>
                    <div className="link" onClick={scrollDown}>
                    <img src={require("../assets/arrow.png")} alt="" style={{ width: "50px" }} />
                    <a target="_blank" href="https://api.whatsapp.com/send?phone=7799420720&text=WELCOME GameTheory">
                        <img src={require("../assets/ww.png")} alt="" className="wt_img" />
                    </a>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer