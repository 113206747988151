import React from "react";
import "../../styles/gamingpage.css";
import Navbar from "./Navbar";
import Footer from "../Footer";

const Gamingpage = () => {

    return (
        <>
            <Navbar />
            <div>
                <section>
                    <span className="animate-charcter">Enjoy Gaming &amp; Your Favourite Cup Of Coffee & Delicious Food!</span>
                </section>
                <div className="glitch">
                    {[...Array(5)].map((_, index) => (<div key={index} className="glitch__item"></div>))} {/* <h1 className="glitch__title">Get <br /> an exclusive gaming experience. </h1> */}
                </div>
            </div>
            <div className="loader">
                <h2>WELCOME TO GAMERS ZONE</h2>
                <h1>Discover India's Most Loved</h1>
                <h2>Vast Game Library Across Diverse Genres</h2>
            </div>
            <div className="container">
                <div className="box">
                    <div className="imgBx">
                        <img src="https://images.unsplash.com/photo-1542751371-adc38448a05e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt='main_ing' />
                    </div>
                    <div className="content">
                        <div>
                            <h2>Pc Games</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto. </p>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBx">
                        <img src="https://t3.ftcdn.net/jpg/06/03/15/90/240_F_603159076_sgsLkDwlcf5XnX2VBNxRW6N41WhwVFEQ.jpg" />
                    </div>
                    <div className="content">
                        <div>
                            <h2>Pc Games</h2>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi accusamus molestias quidem iusto. </p>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBx">
                        <img src="https://images.unsplash.com/photo-1535223289827-42f1e9919769?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    </div>
                    <div className="content">
                        <div>
                            <h2>Vr Meta screens</h2>
                            <p>Explore VR on a platform you can trust, with enhanced privacy features that encrypt your data and supervision tools that keep content friendly for everyone in the family.. </p>
                        </div>
                    </div>
                </div>
                <div className="box">
                    <div className="imgBx">
                        <img src="https://images.unsplash.com/photo-1606144042614-b2417e99c4e3?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" />
                    </div>
                    <div className="content">
                        <div>
                            <h2>PlayStation®5</h2>
                            <p>An all-digital version of the PS5 console with no disc drive. Sign in to your account for PlayStation Network and go to PlayStation Store to buy and download digital PS5 and PS4 games.. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <h1 className="title"> Gametheroy</h1>
                <h1 className="title title-large">Gamers</h1>
                <div id="img-1" className="img-container">
                    <img className="img" src="https://cdn.wallpapersafari.com/78/74/QfSdUt.jpg" />
                </div>
                <div className="img-container second-animation">
                    <img className="img" src="https://wallpapershome.com/images/pages/pic_v/13886.jpg" />
                </div>
                <div className="img-container third-animation">
                    <img className="img" src="https://images2.alphacoders.com/474/thumb-1920-474206.jpg" />
                </div>
                <div className="img-container fourth-animation">
                    <img className="img nba" src="https://wallpapercave.com/wp/wp3639738.jpg" />
                </div>
                <div className="img-container fifth-animation">
                    <img className="img" src="http://hdqwalls.com/wallpapers/fortnite-g5.jpg" />
                </div>
                <div id="img-6" className="img-container sixth-animation">
                    <img className="img" src="http://orig15.deviantart.net/3c71/f/2016/121/9/4/reaper_wallpaper__overwatch__by_prollgurke-da0wf9m.png" />
                </div>
                <div id="img-7" className="img-container seventh-animation">
                    <img className="img" src="https://images4.alphacoders.com/885/thumb-1920-885543.jpg" />
                </div>
            </div>
            <div className="marquee margin_tp">
                <div className="marquee__group">
                    <img src='https://wallpapercave.com/dwp1x/wp1810702.png' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/PCGGiJM.jpg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12405105.jpeg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12147936.jpg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12829612.jpg' alt='' />
                </div>
            </div>
            <div className="marquee marquee--borders">
                <div className="marquee__group">
                    <p>The Dogs of Unsplash</p>
                    <p>Esports Games Catalog</p>
                    <p>The Games of Unsplash</p>
                </div>
                <div className="marquee__group">
                    <p>Esports Games Catalog</p>
                    <p>The Games of Unsplash</p>
                    <p>Esports Games Catalog</p>
                </div>
            </div>
            <div className="">
                <div className="marquee__group">
                    <img src='https://wallpapercave.com/dwp1x/wp1810702.png' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/PCGGiJM.jpg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12405105.jpeg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12147936.jpg' alt='' />
                    <img src='https://wallpapercave.com/dwp1x/wp12829612.jpg' alt='' />
                </div>
            </div>
        </>
    )
}

export default Gamingpage