import React from "react"
import "../../styles/menu.css"
import Navbar from "./Navbar"
import Footer from "../Footer"


const menu = () => {

    return (
        <div className="main_menu">
            <Navbar />
            <div className="content">
                <h1>Enjoy Your Delicious Favourite Food!</h1>
                <ul className="boxes">
                    <li className="box">
                        <h2>BREAKFAST OFFERINGS </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;2</em>
                    </span>
                    <span>Large <em>&#8377;4</em>
                    </span>
                    </p> */} <dl>
                            <dt>Two Eggs of Your Choice with</dt>
                            <dd className="description">
                                <p>Smoky, strong, and assertive, just like us.</p>
                            </dd>
                            <dt>Chicken Bacon/ Ham/ Sausages and Grilled Tomato Choise of You Side</dt>
                            <dd className="description">
                                <p>Smooth and mellow with hints of molasses.</p>
                            </dd>
                            <dt>Thriple Eggs of Your Choice with</dt>
                            <dd className="description">
                                <p>Full bodied and rich, we promise you won't miss the caffiene.</p>
                            </dd>
                            <dt>Chicken Bacon/ Ham/ Sausages and Grilled Tomato Choise of You Side</dt>
                            <dd className="description">
                                <p>Full bodied and rich, we promise you won't miss the caffiene.</p>
                            </dd>
                            <dt>Sunny Side up / Over Easy / Over Medium/ Over Well Done</dt>
                            <dd className="description">
                                <p>Full bodied and rich, we promise you won't miss the caffiene.</p>
                            </dd>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Omelettes </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;2</em>
                    </span>
                    <span>Large <em>&#8377;4</em>
                    </span>
                    </p> */} <dl>
                            <dt>Two Eggs Stuffed Omeletter wtih Choice of your Stuffings </dt>
                            <dd className="description">
                                <p>Smoky, strong, and assertive, just like us.</p>
                            </dd>
                            <dt>Thriple Egg Stuffed Omeletter with Choice of your Stuffing </dt>
                            <dd className="description">
                                <p>Smooth and mellow with hints of molasses.</p>
                            </dd>
                            <dt>Stuffing</dt>
                            <dd className="description">
                                <p>Full bodied and rich, we promise you won't miss the caffiene.</p>
                            </dd>
                            <dt>English Veggies </dt>
                            <dd className="description">
                                <p>( Bell Pepper/ Mushroom / Zucchini)</p>
                            </dd>
                            <dt>Classic Masala</dt>
                            <dd className="description">
                                <p> (Onion/ Tomato/Green Chillies/ Cilantro)</p>
                            </dd>
                            <dt>Chicken Ham/ Bacon / Pepperoni</dt> {/* <dd className="description">
                        <p> (Onion/ Tomato/Green Chillies/ Cilantro)</p>
                    </dd> */} <dt>Mozzerala/ Cheddar Cheese</dt> {/* <dd className="description">
                        <p> (Onion/ Tomato/Green Chillies/ Cilantro)</p>
                    </dd> */}
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Starch / Sides </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Roasted Chickpeas / Baked Beans / Saute Sprouts </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>Hash Brown Potato/ Roasted Potato Wedges / Veg Nuggets </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt> </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */}
                        </dl>
                    </li>
                    <li className="box">
                        <h2>French Fries  </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Regular French Fries </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>Peri Peri / Chipotle / Fajita Tossed French Fries</dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */}
                            <dt> Baked Cheese French Fries</dt>
                            <dd className="description">
                                <p> (French Fries Topped with Cheese, Peppers, Olives, Gherkins ,Ranchi , Tomatos . Onions, Herbs and Gratinated served with Salsa & Sour Cream)</p>
                            </dd>
                            <dt> Baked Nachoes Veg </dt>
                            <dd className="description">
                                <p> (Nachos Topped with Cheese, Peppers, Olives, Gherkins ,Ranchi , Tomatos . Onions, Herbs and Gratinated served with Salsa & Sour Cream)</p>
                            </dd>
                            <dt>Baked Chicken Cheese French Fries  </dt>
                            <dd className="description">
                                <p> (French Fries Topped with Cheese, Peppers, Olives, Gherkins ,Ranchi , Tomatos . Onions, Herbs and Gratinated served with Salsa & Sour Cream)</p>
                            </dd>
                            <dt> Baked Chicken Nachoes</dt>
                            <dd className="description">
                                <p>(Nachos Topped with Cheese, Peppers, Olives, Gherkins ,Ranchi , Tomatos . Onions, Herbs and Gratinated served with Salsa & Sour Cream) </p>
                            </dd>



                        </dl>
                    </li>
                    <li className="box">
                        <h2>Pan Cakes </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Pan Cakes </dt>
                            <dd className="description">
                                <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                                </p>
                            </dd>
                            <dt>Vanilla Pancake </dt>
                            <dd className="description">
                                <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                            </dd>
                            <dt>Choco Chip Pancakes </dt>
                            <dd className="description">
                                <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                            </dd>
                            <dt>American Berry Pancakes</dt>
                            <dd className="description">
                                <p>1 shot espresso, two shots hot water.</p>
                            </dd>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>French Toast</h2>
                        <p className="tip">Served to brave people in our notorious antique tea cups.</p>
                        <p className="prices">
                            <span>Per Cup <em>&#8377;2</em>
                            </span>
                            <span className="note">
                                <em>&#8377;200</em>if you break the cup </span>
                        </p>
                        <dl>
                            <dt>Classic French Toast </dt>
                            <dd className="description">
                                <p>Black tea fragranced with bergamot.</p>
                            </dd>
                            <dt>Peanut Butter Banana French Toast</dt>
                            <dd className="description">
                                <p>Floral, tart, and spicy. Caffeine free.</p>
                            </dd>
                            <dt>Fruit Compote French Toast </dt>
                            <dd className="description">
                                <p>A blend of green teas hand selected by our master teamaker.</p>
                            </dd>
                            <dt>Croissant</dt>
                            <dd className="description">
                                <p>Soothing and slightly sweet. Try it with honey! Caffeine free.</p>
                            </dd>
                            <dt>Danish</dt>
                            <dd className="description">
                                <p>slightly sweet. Try it with honey! Caffeine free.</p>
                            </dd>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Waffles</h2> {/* <p className="prices">
                    <span>Cookies <em>&#8377;2</em>
                    </span>
                    <span>Muffins &amp; Pastries <em>&#8377;3</em>
                    </span>
                    </p> */} <dl>
                            <dt>Vanilla Waffles </dt>
                            <dd className="description">
                                <p>Creamy cinnamony goodness made from scratch with our founder Mallory's prizewinning recipe.</p>
                            </dd>
                            <dt>Dry Nuts and Chocolate Waffles </dt>
                            <dd className="description">
                                <p>Packed with rolled oats, raisins, and cranberries, these monster cookies are (almost) good for ya!</p>
                            </dd>
                            <dt>Berry Berry Waffles </dt>
                            <dd className="description">
                                <p>Filled with the best berries of the season and topped with a crumb crust. </p>
                            </dd>
                            <dt>Banana and Peanut Butter Waffles </dt>
                            <dd className="description">
                                <p>Our oversized croissants are buttery, flaky, and usually all sold out by 7 a.m.</p>
                            </dd>
                            <dt>Topped wtih Whip Cream and Syrup </dt>
                            <dd className="description">
                                <p>Our oversized croissants are buttery, flaky, and usually all sold out by 7 a.m.</p>
                            </dd>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Sandwiches</h2> {/* <p className="tip">Served to brave people in our notorious antique tea cups.</p>
                    <p className="prices">
                    <span>Per Cup <em>&#8377;2</em>
                    </span>
                    <span className="note">
                        <em>&#8377;200</em>if you break the cup </span>
                    </p> */} <dl>
                            <dt>Vegetable Pesto Cheese Sandwich </dt>
                            <dd className="description">
                                <p>(White Jumbo , Tomato, Cucumber , Peppers , Pesto , Mayoo, Cheese Slices)</p>
                            </dd>
                            <dt>Grilled Paneer Tikka Sandwich </dt>
                            <dd className="description">
                                <p>(Whole Wheat Bread or Panini , Roasted Paneer Tikka , Cucumber , Lettuce , Onion , Mint Mayos , Cheese Slice)</p>
                            </dd>
                            <dt>BBQ Paneer Sandwich </dt>
                            <dd className="description">
                                <p>(Cibatta Bread, BBQ Paneer, Cucumber, Onion , Lettuce, Garlic Mayo, Cheese)</p>
                            </dd>
                            <dt>Double Cheese Veg Club Sandwich </dt>
                            <dd className="description">
                                <p>( Double Layer Jumbo Bread , Crispy Veg, Cucumber, Tomato, Onion , Lettuce, Arora Sauce, Cheddar, Mozzerala)</p>
                            </dd>
                            <dt>Chicken Ham and Cheese Sandwich </dt>
                            <dd className="description">
                                <p>( White Jumbo, Chicken Ham, Cheese , Tomato , Cumber , Lettue , Cheese, Mayoo)</p>
                            </dd>
                            <dt>Double Chicken Tikka Club Sandwich </dt>
                            <dd className="description">
                                <p>( Double Layer Jumbo Bread , Crispy Chicken / Ham, Cucumber, Tomato, Onion , Lettuce, Arora Sauce, Cheddar, Mozzerala)</p>
                            </dd>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Pasta</h2>
                        <p className="prices">
                            <span>Cookies <em>&#8377;2</em>
                            </span>
                            <span>Muffins &amp; Pastries <em>&#8377;3</em>
                            </span>
                        </p>
                        <dl>
                            <dt>Penne Arrabbiaca Sauce </dt>
                            <dd className="description">
                                <p>( Penner pasta Infiery hot sauce made with tomatoes, olive oil, garlic, and red chili Flakes)</p>
                            </dd>
                            <dt>Fussili Pasta in Creamy Tomato & Cheese Sauce</dt>
                            <dd className="description">
                                <p>(Fussili pasta tossed in tomatoes Sauce,Cream Sauce, olive oil, garlic, and Cheese )</p>
                            </dd>
                            <dt>Spaghetti in Alfredo sauce</dt>
                            <dd className="description">
                                <p>(Spaghettin Tossed in Cream White Sauce with Butter, Garlic, Parmesan Cheese ). </p>
                            </dd>
                            <dt>Penne in Pesto Sauce</dt>
                            <dd className="description">
                                <p>(Penne Tossed In Crushed garlic, pine nuts, coarse salt, basil leaves, and Parmesan Cheese ) </p>
                            </dd>
                            <dt> Fussili Pasta in Creamy Chicken Tomato & Cheese Sauce </dt>
                            <dd className="description">
                                <p> (Fussili pasta tossed in tomatoes Sauce,Cream Sauce, olive oil, garlic, and Cheese )</p>
                            </dd>
                            <dt> Spaghetti in Chicken Alfredo Sauce</dt>
                            <dd className="description">
                                <p> (Spaghettin Tossed in Cream White Sauce with Butter, Garlic, Parmesan Cheese )(Choose of Vegetables Peppers /Zucchini / Corn / Mushroom/Baby Corn)</p>
                            </dd>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Burger or Baps </h2> {/* <p className="tip">Served to brave people in our notorious antique tea cups.</p>
                    <p className="prices">
                    <span>Per Cup <em>&#8377;2</em>
                    </span>
                    <span className="note">
                        <em>&#8377;200</em>if you break the cup </span>
                    </p> */} <dl>
                            <dt>Crunchy Spicy Potato Burger </dt>
                            <dd className="description">
                                <p> (Herbs Chilli Potato Patty & Smoked Veggeis, Tomato ,Lettuce, BBQ Sauce, Garlic Aioli with Cheese)</p>
                            </dd>
                            <dt>Traditional Veg Tikka Burger </dt>
                            <dd className="description">
                                <p>(Vegetable Masala Tikka Patty & Tomato / Cucumber /Lettuce/ Pickles Veggies / Mint Mayo with Cheese )</p>
                            </dd>
                            <dt>Cafe Classic Paneer Burger </dt>
                            <dd className="description">
                                <p>(Paneer Patty & Smoked Veggeis, Tomato, Cucumber, Lettuce,Caramalized Onion, Gherkins with Arora Sauce Cheese)</p>
                            </dd>
                            <dt>Cafe Classic Chicken & Fried Egg Burger </dt>
                            <dd className="description">
                                <p>(Spicy Chicken Patty, Fried Egg & Smoked Veggeis, Tomato, Cucumber, Lettuce,Caramalized Onion, Gherkins with Arora Sauce Cheese)</p>
                            </dd>
                            <dt>Smoked Chicken Stack & Bacon Burger </dt>
                            <dd className="description">
                                <p>(Chicken Cutlette Patty , Chicken Bacon, Tomato, Cucumber, Lettuce,Caramalized Onion, with Arora Sauce Cheese)</p>
                            </dd>
                            <dt>Pulled Chicken & Bacon Burger </dt>
                            <dd className="description">
                                <p>(BBQ Pulled Chicken, Chicke Bacon, Tomato, Cucumber, Lettuce, Mint Mayo, Cheese)</p>
                            </dd>
                            <dt>Sides Coleslaw / Ketchup / Sweet Potato Chips or Potato Chips</dt>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Pizza</h2>
                        <p className="tip">All Pizzas will be of three Sizes Small 6 "/ Regular 8 " / Larger 12" </p>
                        <dl>
                            <dt>Classic Margerita Pizza </dt>
                            <dd className="description">
                                <p>(Thin Crust Pizza, Base Sauce, Tomato ,Basil, Mozzerala Cheddar Cheese)</p>
                            </dd>
                            <dt>Green Goddess Pizza</dt>
                            <dd className="description">
                                <p>(Thin Crust , Basil Mint Pesto Sauce, Capsicum, Broccoli ,Zucchini, Olives , Gherkins, Lettue Green, Cheddar Mozzerala)</p>
                            </dd>
                            <dt>Mushroom & Olives Garden Pizza </dt>
                            <dd className="description">
                                <p>( Pizza Base , Pizza Sauce, Mushroom, Olives , Peppers , Onion, Cheddar Mozzerala ) </p>
                            </dd>
                            <dt>Tex Mex Pzza </dt>
                            <dd className="description">
                                <p>( Pizza Base , Pizza Sauce,Sweet Corn, Tomato , Olives , Peppers ,Nachos Crumbs Onion, Cheddar Mozzerala, Salsa Top )</p>
                            </dd>
                            <dt>Classic Pepperoni Margerita Pizza</dt>
                            <dd className="description">
                                <p>(Thin Crust Pizza, Base Sauce, Chicken Pepperoni , Bacon ,Tomato ,Basil, Mozzerala Cheddar Cheese)</p>
                            </dd>
                            <dt>Smoked Chicken Pizza</dt>
                            <dd className="description">
                                <p>(Thin Crust Pizza, Base Sauce, Smoked Chicken Sausage , Onion ,Bell Pepper,Olives Basil, Mozzerala Cheddar Cheese)</p>
                            </dd>
                            <p className="tip">Pizza Accompaniments Oregano / Chilli Flakes / Ketchup </p>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Starters Veg </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Vegetable Manchuri Dry/ Wet </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>Gobi Manchuria </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt>Paneer Majestic </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */} <dt> Chilli Mushroom </dt>
                            <dt>Chilli Paneer </dt>
                            <dt>Paneer 65</dt>
                            <dt>Cripspy Corn Kennels </dt>
                            <dt>Vegetable Spring Rolls </dt>
                            <dt>Honey Chili Potato Wedges </dt>
                            <dt>Honey Chilli Baby Corn</dt>
                            <dt>Schezwan Paneer </dt>
                            <dt>Paneer Zafrani Tikka</dt>
                            <dt>Achari Paneer </dt>
                            <dt>Hariyali Paneer Tikka</dt>
                            <dt>Tandoori Broccoli</dt>
                            <dt>Subzi Sheek Kabab</dt>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Starters NON - Veg </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Egg Manchuria </dt>
                            <dd className="description">
                                <p>(6 Half Eggs)</p>
                            </dd>
                            <dt>Chicken Manchuria </dt>
                            <dd className="description">
                                <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                            </dd>
                            <dt>Chilli Eggs </dt>
                            <dd className="description">
                                <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                            </dd>
                            <dt>Chilli Chicken</dt>
                            <dd className="description">
                                <p>1 shot espresso, two shots hot water.</p>
                            </dd>
                            <dt> Chilli Chicken</dt>
                            <dt>Schezwan Chicken </dt>
                            <dt> Chilli Chicken Wings </dt>
                            <dt> Drums of Heaven</dt>
                            <dt> Butter Garlic Prawns </dt>
                            <dt> Fish Salt & Pepper </dt>
                            <dt>Tempura Prawns </dt>
                            <dt>Apollo Fish </dt>
                            <dt>Murgh Gilaf Kebab </dt>
                            <dt>Tandoori Murgh </dt>
                            <dt>Haiyali Chicken Tikka </dt>
                            <dt>Zaffrani Murgh </dt>
                            <dt>Mutton Sheek Gilaf </dt>
                            <dt>Fish Tikka </dt>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Noodles </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Veg Maggi Bowl </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>Schezwan Veg Noodles </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt>Vegetable Hakka Noodles </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */} <dt> Singaporian Veg Noodles </dt>
                            <dt>Egg Noodles </dt>
                            <dt> Schezwan Egg Noodles </dt>
                            <dt> Schezwan Chicken Noodles </dt>
                            <dt>Chicken Hakka Noodles </dt>
                            <dt> Prawns Noodles </dt>
                            <dt>Mix Non Veg Noodles </dt>
                            <dt>Chicken Maggi Bowl </dt>
                        </dl>
                    </li>
                    <li className="box">
                        <h2>Fried Rice </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Jeera Veg Fried Rice </dt> {/* <dd className="description">
                        <p>(6 Half Eggs)</p>
                    </dd> */} <dt>Schezwan Veg Fried Rice </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt>Vegetable Fried Rice </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */} <dt>Egg Fried Rice</dt> {/* <dd className="description">
                        <p>1 shot espresso, two shots hot water.</p>
                    </dd> */} <dt> Schezwan Egg Fried Rice</dt>
                            <dt>Schezwan Chicken Fried Rice </dt>
                            <dt>Mix Non Veg Fried Rice </dt>
                        </dl>
                    </li>
                </ul>
                <ul className="boxes">
                    <li className="box">
                        <h2>Indian Mains </h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>Dal Tadka </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>Dal Makhni </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt>Rajma Masala </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */} <dt> Paneer Butter / Paneer Shahi / Shahi Paneer / Palak Paneer </dt>
                            <dt> VEGETABLE MAKHANWALA! </dt>
                            <dt> Creamy tomato gravy! Cardamom! Kashmiri chili </dt>
                            <dt> KUMB MAKAI PALAK </dt>
                            <dt> Button mushroom! Baby corn </dt>
                            <dt> Spinach paste! Green chili! Cumin and spices </dt>
                            <dt> MALAI KOFTA </dt>
                            <dt> Cottage cheese dumpling! White cashew gravy </dt>
                            <dt> MURGH DHANIYA LAZEEZ </dt>
                            <dt> Coriander gravy! Green chili! Ghee </dt>
                            <dt> BUTTER CHICKEN </dt>
                            <dt> Cooked in silky tomato and butter gravy </dt>
                            <dt> CHICKEN TIKKA MASALA </dt>
                            <dt> Tandoori smoked chicken! Spicy tomato gravy with spices </dt>
                            <dt> CHICKEN CHAAP MASALA</dt>
                            <dt> Tandoori chicken chop! Rich cashew gravy </dt>
                            <dt> MURGH AMBADA </dt>
                            <dt> Sorrel leaf! Onion! tomato! Indian spices </dt>
                            <dt> CHANNA GOSHT </dt>
                            <dt> Bone less mutton! Chickpeas! Garam masala </dt>
                            <dt> ACHARI GHOST </dt>
                            <dt> Cooked in pickled spices! Tempered with curry leaves </dt>
                            <dt> GOSHT KA KORMA </dt>
                            <dt> Brown onion! Cashew and melon seed gravy! Clarified butter and spices </dt>
                            <dt> RARA GOSHT</dt>
                            <dt> Boneless lamb and mince! Indian spices  </dt>
                            <dt> KHEEMA KAJU </dt>
                            <dt> Lamb mince! Cashew! Indian spices </dt>
                            <dt> NALLI KHEEMA</dt>
                            <dt>Lamb shanks and mince! Flavoured with cardamom and green choli </dt>
                            <dt> NALLI NIHARI</dt>
                            <dt> Slow cooked lamb shanks with classic potli spices </dt>
                            <dt> SARSOON MACH</dt>
                            <dt> Mustard oil! Mustard green chili paste! Bay leaf </dt>
                            <dt> ACHARI FISH </dt>
                            <dt> Pickle spices! Murrel! Red dry chili! Curry leaf </dt>
                            <dt> FISH MASALA</dt>
                            <dt> Home style fish curry </dt>
                            <dt>JHINGA MASALA </dt>
                            <dt>Prawn cooked with onion tomato gravy and lemon  </dt>
                            <dt> JHINGA HARA MASALA</dt>
                            <dt> Coriander spinach gravy! Green chili! Indian spices </dt>
                            <dt> JHINGA KALIMIRCH</dt>
                            <dt> Cashew gravy! Black pepper </dt>
                            <dt>  HYDERABADI DUM BIRYANI! VEGETABLE/ CHICKEN/ LAMB / NALLI 490/ 590/ 650/790 </dt>
                            <dt>  Served with Cucumber Raita and Mirchi ka salan. </dt>
                            <dt> STEAMED RICE! Basmati </dt>


                        </dl>
                    </li>
                    <li className="box">
                        <h2>INDIAN BREADS</h2> {/* <p className="prices">
                    <span>Small <em>&#8377;3</em>
                    </span>
                    <span>Large <em>&#8377;5</em>
                    </span>
                    </p> */} <dl>
                            <dt>ROTI! PLAIN/ BUTTER/MIRCHI </dt> {/* <dd className="description">
                        <p>Espressso and steamed milk, served in a demitasse cup. <span className="note">(Size Small Only)</span>
                        </p>
                    </dd> */} <dt>MAKKI KI ROTI </dt> {/* <dd className="description">
                        <p>Espresso with steamed milk, and sometimes a little art on top. </p>
                    </dd> */} <dt>NAAN! PLAIN/BUTTER NAN/GARLIC NAAN/ CHEESE NAAN </dt> {/* <dd className="description">
                        <p>Espresso with steamed chocolate milk and whipped cream. Also available with white chocolate milk.</p>
                    </dd> */} <dt> PARATHA! LACHHA / ALOO/ PANEER </dt>

                        </dl>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default menu