import React from 'react';
import "../assets/c1.png";
import "../assets/t3.jpg";
import "../assets/x4.jpg";

import '../styles/ourstory.css'
import Navbar from '../components/home/Navbar';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';


const Ourstory = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>About Cafe Game Theory - The Best Destination for Entertainment, Delicious Food & More</title>
        <meta name="og:description" content="Cafe Game theory is not just a 24x7 Gaming Zone or a Cozy Cafe. It is a Fulfledged Casual Dining Retuarant with a wide array of indian, Continental and Chinese Cuisines " />
      </Helmet>
      <div className='overflow-hidden '>
        <article className="article_group">
          <h2 className='font_hd margin_tp margin_lft margin_rgt'> About us</h2>
          <section>
            <div className="ourcomponets">
              <img src={require("../assets/x4.jpg")} className="img_right" />
              <p className='margin_lft margin_tp'> <b>Cafe Game Theory</b> A comprehensive casual dining establishment offering an extensive selection of Indian, Continental, and Chinese cuisines. 🍽️🌶️🍜. It offers an exclusive private theatre and event celebration experience. Guests can reserve our theatres for watching their favourite movies and shows on a large screen, as well as celebrating memorable occasions such as birthdays, anniversaries, dates and many more. We offer high-fidelity audio of Dolby Atmos compliant sound systems and 4k Video.
              </p>
            </div>
          </section>
          <section>
            <div className="newcomponets margin_btm">
              <img className="img_left" src="https://img.freepik.com/premium-photo/coffee-house-concrete-walls-wooden-furniture_124865-22155.jpg" alt="gtimage" />
              <p className='margin_lft margin_tp'> <b>Cafe Game Theory’s</b> The little coffee shop is lit up by fairy lights, a small touch made by the barista that is currently working their night shift there. The city outside is still busy, which is no surprise considering the city is always busy - that’s 'kompally' for you..
              </p>
            </div>
          </section>
        </article>
      </div>
    </>
  );
};

export default Ourstory;