import React from "react";
import { Helmet } from "react-helmet"
import "../styles/medium.css"

const GamingTheme = () => {
  return (
  
    <main className="med_main">
    <Helmet htmlAttributes>
        <html lang="en" />
        <title>Experience the Best Gaming Lounge in Kompally - Cafe Game Theory </title>
        <meta name="og:description" content="Play the latest PC gaming titles like CS 2, DOTA 2, Valorant. Participate in Rebellion eSports Tournaments and Win exciting Cash Prizes. The Console Lounge consists of a Wide slection of Sony PS5 Titles to play from incl God of War, Tekken, Mortal Kombat, WWE, Fifa, etc" />
    </Helmet>
    <section className="promo_area">
      <div className="main-title">
        <div>
          <p className="font_hd"><nobr>Premium Gaming Lounge</nobr></p>
          <div className="dot"></div> 
        </div>
      </div>
      <div className="main-para">
        <h1 className="med_head"><nobr><p className="font_hd">Futurist Retro Ambience</p></nobr></h1>
        <p className="txt-grey txt-center"> Vibe in Style. 24x7. Gaming Cafe, that pays homage to all your favorite 80’s games. You can drink specialty game themed mocktails, browse through dozens of old strategy guides, play with all the gaming merchandise on the shelves, and play on one of the many consoles</p>
        <span className="btn_cen">
          <p className="view_btn">LEARN MORE....&nbsp; &nbsp; &#8594;</p>
        </span>
      </div>
      <div className="round-planet planet">
        <div className="round-planet planet2">
          <div className="round-planet planet3">
            <div> <img src={require("../assets/vr_boy.jpeg")} className="med_img" /> </div>
          </div>
          <div className="star star1"></div>
          <div className="star star2"></div>
          <div className="star star3"></div>
          <div className="star star4"></div>
          <div className="star star1 star5"></div>
          <div className="star star2 star6"></div>
          <div className="star star3 star7"></div>
          <div className="star star4 star8"></div>
        </div>
      </div>
    </section>
  </main>
  
  );
};

export default GamingTheme;
