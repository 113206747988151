import { BrowserRouter, Routes, Route, } from "react-router-dom"
import GamingTheme from "../pages/GamingTheme"
import HomeScreen from "./HomeScreen"
import Boardgame from "../pages/Boardgame"
import Gtmovie from "../pages/Gtmovie"
import ContactForm from "../pages/Contactus"
import Ourstory from "../pages/Ourstory"
import Gamingpage from "./home/Gamingpage"
import Menu from "./home/Menu"
import Layout from "./Layout"
import BookSlot from "../pages/BookSlot"
import Pay from "../pages/Pay"


function GTRoutes () {
    return (
        <BrowserRouter>
        <Layout>
            <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/our-story" element={<Ourstory />} />
            <Route path="/contact-us" element={<ContactForm />} />
            <Route path="/mini-theatre" element={<Gtmovie />} />
            <Route path="/board-games" element={<Boardgame />} />
            <Route path="/gaming-pc-ps5" element={<GamingTheme />} />
            <Route path="/menu" element={<Menu />} />
            <Route path="/games" element={<Gamingpage />} />
            <Route path="/book-slot" element={<BookSlot />} />
            <Route path="/pay" element={<Pay />} />
            </Routes>
        </Layout>
        </BrowserRouter>
    )
}

export default GTRoutes