import React from "react"
import "../styles/boardgame.css"
import Footer from "../components/Footer"
import { Helmet } from "react-helmet"

const Boardgame = () => {
    return (
        <>
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Best Board Game Cafe in Kompally, Hyderabad - Cafe Game Theory </title>
                <meta name="og:description" content="Play the latest Board Games while sipping your favourite cup of Coffee or having brunch on a Saturday in Kompally. participate in Art Workshops, Live events, bring your dates for unforgettable moments!" />
            </Helmet>
            <section className="main-home overflow-hiiden">
                <div className="container_img margin">
                    <img src={"https://images.squarespace-cdn.com/content/v1/5ebee2f685f4eb2fd43b9e42/1596810856649-QYZTNO8I87GTZ79GX16K/GAME-THEORY-3.jpg"} className="banner-img" alt="banner" />
                    <h2 style={{ textAlign: "center" }} className="centered "> Game Theory </h2>
                    <br />
                    <h3 className="centeres"> Experience Fort Worth’s hottest new game lounge and restaurant. Bring the unique fun home by joining Game Theory’s exclusive member program today{" "} </h3>
                    <h3 className="centeress"> Explore all 3 membership tiers and learn more about the Game Theory story </h3>
                </div>
                <h2 style={{ textAlign: "center", fontSize: "30px" }}> ѕєℓє¢т α мσηтнℓу мємвєяѕнιρ ℓєνєℓ{" "} </h2> <br />
                <div className="table_row">
                    <div className="table-cols">
                        <p style={{ textAlign: "center", fontSize: "30px" }}>Date Night </p>
                        <img src={"https://plus.unsplash.com/premium_photo-1661431040004-511af14f1b18?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8bG92ZSUyMGNhcHB1Y2Npbm98ZW58MHx8MHx8fDA%3D"} className="pictures-img" alt="banner" />
                        <h3 className="para-price"> &#x20B9;2999/- <p style={{
                            textAlign: "center",
                            fontSize: "20px",
                            color: "white",
                        }}> PER MONTH </p>
                        </h3>
                        <button className="btns">Learn More</button>
                        <h3 className="para-head">Who It’s For </h3>
                        <p className="para-main"> For fans of board games and love. Get curated recommendations from Game Theory’s game guides, and enjoy a romantic meal while you play at home. </p>
                        <p className="para-main1">
                            <b>2 recommended games</b> every month, for a week’s long rental:
                            <ul>
                                <li style={{ marginBottom: "40px" }}> 1 that we know you like </li>
                                <li style={{ marginBottom: "40px" }}> 1 that we think you’ll like (based on your past preferences, and our curated selections) </li>
                            </ul>
                        </p>
                    </div>
                    <div className="table-cols">
                        <p style={{ textAlign: "center", fontSize: "30px" }}> Family Fun Package{" "} </p>
                        <img src={"https://images.pexels.com/photos/6115006/pexels-photo-6115006.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} className="pictures-img" alt="banner" />
                        <h3 className="para-price"> &#x20B9;12999/- <p style={{
                            textAlign: "center",
                            fontSize: "20px",
                            color: "white",
                        }}> PER MONTH{" "} </p>{" "} </h3>
                        <button className="btns">Learn More</button>
                        <h3 className="para-head">Who It’s For </h3>
                        <p className="para-main"> {" "} For the family with children aged 8-18 and, larger groups of all ages. Get the Game Theory experience at home, and enjoy a delicious meal with your family.{" "} </p>
                        <h3 className="para-head">What You’ll Get</h3>
                        <p className="para-main1">
                            <b>2 recommended games</b> every month, for a week’s long rental:
                            <ul>
                                <li style={{ marginBottom: "40px" }}> 1 that we know you like </li>
                                <li style={{ marginBottom: "40px" }}> 1 that we think you’ll like (based on your past preferences, and our curated selections) </li>
                            </ul>
                        </p>
                    </div>
                    <div className="table-cols">
                        <p style={{ textAlign: "center", fontSize: "30px" }}> Board Game Party{" "} </p>
                        <img src={"https://images.pexels.com/photos/6333892/pexels-photo-6333892.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"} className="pictures-img" alt="banner" />
                        <h3 className="para-price"> &#x20B9;22999/-{" "} <p style={{
                            textAlign: "center",
                            fontSize: "20px",
                            color: "white",
                        }}> PER MONTH </p>{" "} </h3>
                        <button className="btns">Learn More</button>
                        <h3 className="para-head">Who It’s For </h3>
                        <p className="para-main"> For large groups who miss looking forward to and enjoying a monthly game night. Take the ease of hosting a party for friends at home without lifting a finger, and enjoy the fun!{" "} </p>
                        <h3 className="para-head">What You’ll Get </h3>
                        <p className="para-main1"> 3 recommended party-style games (for groups of 4-8) every month, for a week’s long rental:
                            <ul>
                                <li style={{ marginBottom: "40px" }}> 2 that we know you like </li>
                                <li style={{ marginBottom: "40px" }}> 1 that we think you’ll like (based on your past preferences, and our curated selections) </li>
                            </ul>
                        </p>
                    </div>
                </div>
                <div> {" "} <h2 style={{ textAlign: "center" }}> Your choice of: </h2>
                </div>
                <div className="table_row">
                    <div className="table-cols">
                        <p className="para-main2"> Bad & Boujee charcuterie board for 2
                            <ul>
                                <p className="para-main2">2 slices of dessert pie </p>
                                <p className="para-main2">Cocktail bottle to-go, for 2 </p>
                            </ul>
                        </p>
                    </div>
                    <div className="table-cols">
                        <p className="para-main1"> Taco Night dinner for your family (feeds 4), <b>includes:</b>
                            <ul>
                                <li style={{ marginBottom: "40px" }}> Shrimp, brisket, & chipotle chicken tacos </li>
                                <li style={{ marginBottom: "40px" }}> Salsa, queso & guacamole </li>
                                <li style={{ marginBottom: "40px" }}> Roasted corn & black bean side </li>
                            </ul>
                        </p>
                    </div>
                    <div className="table-cols">
                        <p className="para-main1"> 3 cocktail bottles to-go <b> (12 cocktail equivalent) </b>{" "} </p>
                        <p className="para-main1">
                            <b>Large chip dip duo & popcorn chicken sampler, </b> firecracker, sesame honey and classic flavor
                            <p className="para-main2"> Large chip dip duo & popcorn chicken sampler, with firecracker, sesame honey and classic flavor{" "} </p>
                        </p>
                    </div>
                </div>
                <div> {" "} <h2 style={{ textAlign: "center" }}>OR</h2>
                </div>
                <div className="table_row">
                    <div className="table-cols"></div>
                    <div className="table-cols">
                        <p className="para-main1">
                            <li> Game Theory food classics, includes (feeds 4): </li>{" "} <br></br>
                            <br></br>
                            <ul>
                                <li style={{ marginBottom: "40px" }}>4 mini pies</li>
                                <li style={{ marginBottom: "40px" }}> Family-sized popcorn chicken </li>
                                <li style={{ marginBottom: "40px" }}> Family-sized mac & cheese </li>
                                <li style={{ marginBottom: "40px" }}> Family sized french fries </li>
                            </ul>
                        </p>
                    </div>
                    <div className="table-cols">
                        <p className="para-main1"> Bad & Boujee charcuterie board <b> (12 cocktail equivalent) </b>{" "} </p>
                    </div>
                </div>
                <h2 style={{ textAlign: "center" }}>About GameTheory</h2>
                <br></br>
                <div className="foter_img">
                    <img src={"https://e1.pxfuel.com/desktop-wallpaper/422/370/desktop-wallpaper-gaming-game-video-computer-gamer-poster-gaming-quotes.jpg"} alt="banner" /> <br />
                    <p> “Despite our name, we don't believe in zero-sum games. When people come first, everyone wins.” </p> <br />
                    <h2>
                        <b>cafe game Theory’s </b>
                    </h2>
                    <h3 style={{ textAlign: "center" }}> Our mission is to be the leading facilitator of fun, thereby fostering a positive atmosphere for guests to connect with each other. We aim to create a greater sense of community and operate a business that creates value for everyone - our employees, suppliers, and most importantly our customers.{" "} </h3>
                </div>
            </section>
        </>
    )
}

export default Boardgame