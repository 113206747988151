import React from 'react'
import "../../styles/home.css"

const Banner = () => {

    return (
        <main className="hero-section">
            <div>
            <span>
                <h1 className="font_hd">Kompally's Most Delicious <span>Gamer-Themed Cafe!</span></h1>
            </span>
            <span>
                <h2> Enjoy Gaming along-side your favourite cup of Coffee & delicious Food! </h2>{" "}
            </span>
            <span>
                <p className="">Game Theory is the largest gaming-themed café in Kompally, Secunderabad-Hyderabad. You can choose from a variety of games including PS5 Titles, Board Games, PC Games, consoles, and tiers, and enjoy delicious food and drinks. We also have a pool table and a special in-store shop with exclusive gaming merchandise.</p>
            </span>
            <span>
                <button className="btns">Get Started...</button>{" "}
            </span>
            </div>
            <div className="ban_img_head">
            <img
                src="https://t4.ftcdn.net/jpg/04/42/21/53/240_F_442215355_AjiR6ogucq3vPzjFAAEfwbPXYGqYVAap.jpg"
                className="image_home"
                alt="Gaming Cafe"
            />
            </div>
        </main>
    )
}

export default Banner