import DatePicker from 'react-datepicker'
import '../styles/date-picker.css'

const GTDatePicker = ({ onDateChange, selectedDate }) => {

    const handleDateChange = (date) => {
        onDateChange(date)
    }
    const today = new Date()

    return (
        <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            showDisabledMonthNavigation
            fixedHeight
            minDate={today}
            inline
            calendarClassName="horizontal-calendar"
        />
    )
}

export default GTDatePicker