import React from "react";
import { Link } from "react-router-dom";
import '../styles/gtmovie.css'
import miniTheatreHero from '../assets/mini-theatre-hero.jpg'
import miniTheatreTheatre from '../assets/mini-theatre-theatre.jpg'
import { Helmet } from "react-helmet";

const Gtmovie = () => {
  return (
    <>
      <div className="mini-theatre">
        <Helmet htmlAttributes>
          <html lang="en" />
          <title> Celebrate Birthdays Events - Best Private Movie Theatres in Kompally, Secunderabad</title>
          <meta name="og:description" content="Game Theory provides top-rated private theatres for celebrations and movie screening in Secunderabad and Kompally at the lowest Prices. Celebrate birthdays, anniversaries, dates, and proposals in our best-in-class theatres equipped with Dolby Atmos sound, 4k video, and Apple TV. Get ready to experience Cafe Game Theory at the most trusted private theatres. Reserve now and create unforgettable moments with your loved ones!" />
        </Helmet>
        <div className="hero-section">
          <div className="hero-title">
            <h1>Let's Celebrate Your <span>Special</span> Moments </h1>
            <p>
              Welcome to <b>GameTheory</b>, your premier destination for an extraordinary private theatre and event celebration experience.
              <br />
              <br />
              We specialize in providing exclusive private theatres equipped with Dolby Atmos compliant sound systems and enhanced 4K video projection.
            </p>
            <Link to={'/book-slot'} className="hero-button">Book Now</Link>
          </div>
          <div className="hero-image-wrapper">
            <div className="hero-image">
              <svg width="0" height="0" viewBox="0 0 400 300">
                <defs>
                  <mask id="mask">
                    <rect x="221.1" y="-10.4003" width="259" height="259" rx="29" transform="rotate(30 221.1 -10.4003)" fill="url(#paint0_linear_0_1)" />
                    <rect x="50.8804" y="40" width="111.761" height="111.761" rx="15" transform="rotate(30 50.8804 40)" fill="url(#paint1_linear_0_1)" />
                    <rect x="85.8647" y="220" width="89.7293" height="89.7293" rx="10" transform="rotate(30 85.8647 220)" fill="url(#paint2_linear_0_1)" />
                  </mask>
                  <linearGradient id="paint0_linear_0_1" x1="350.6" y1="-10.4003" x2="350.6" y2="248.6" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C6A178" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                  <linearGradient id="paint1_linear_0_1" x1="106.761" y1="40" x2="106.761" y2="151.761" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C6A178" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                  <linearGradient id="paint2_linear_0_1" x1="130.729" y1="220" x2="130.729" y2="309.729" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#C6A178" />
                    <stop offset="1" stop-color="white" />
                  </linearGradient>
                </defs>
              </svg>
              <img src={miniTheatreHero} alt="hero" />
            </div>
          </div>
        </div>
        <div className="theatres">
          <div className="theatres-title">
            <h1> Best Immersive Experience in the  <span>Hyderabad</span></h1>
            <p>We specialize in providing exclusive private theatres equipped with Dolby Atmos compliant sound systems and enhanced 4K video projection.</p>
            <h4> Private Theatres in Kompally</h4>
            <ul>
              <li>8K supported DVR</li>
              <li>True 4K UHD Resolution Projector</li>
              <li>HDR10, HDR10+, Dolby Vision and Hybrid Log-Gamma</li>
              <li>Accurate reproduction of cinematic colour exactly as the director intended</li>
              <li>High brightness and vivid colours for vibrant, true-to-life visuals  - ideal for watching TV shows, sports and movies or gaming with family or friends</li>
              <li>Upto 150” White Matte Screen for the best Cinematic Experience</li>
            </ul>
            <Link to={'/book-slot'} className="hero-button" >Book Now</Link>
          </div>
          <div className="theatre-image">
            <img src={miniTheatreTheatre} alt="theatre" />
          </div>
        </div>
        <div className="features">
          <h2>Things to note</h2>
          <div className="cards">
            <div className="card">
              <div className="icon">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path d="M31.4453 40.625C31.4453 44.0771 29.4287 46.875 25.9766 46.875H13.0859C9.63379 46.875 7.61719 44.0771 7.61719 40.625M32.8125 32.8125C34.5381 32.8125 35.9375 34.5615 35.9375 36.7188C35.9375 38.876 34.5381 40.625 32.8125 40.625H6.25C4.52441 40.625 3.125 38.876 3.125 36.7188C3.125 34.5615 4.52441 32.8125 6.25 32.8125" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                  <path d="M6.25 26.9531V26.9316C6.25 21.5605 10.6445 18.75 16.0156 18.75H23.0469C28.418 18.75 32.8125 21.582 32.8125 26.9531V26.9316M23.5352 10.9375L24.2617 17.1846M33.5938 32.8125H17.5107C17.3038 32.8126 17.1054 32.8948 16.959 33.041L14.3389 35.6611C14.3026 35.6975 14.2595 35.7263 14.2121 35.7459C14.1647 35.7656 14.1138 35.7757 14.0625 35.7757C14.0112 35.7757 13.9603 35.7656 13.9129 35.7459C13.8655 35.7263 13.8224 35.6975 13.7861 35.6611L11.166 33.041C11.0196 32.8948 10.8212 32.8126 10.6143 32.8125H5.46875C4.84715 32.8125 4.25101 32.5656 3.81147 32.126C3.37193 31.6865 3.125 31.0904 3.125 30.4688C3.125 29.8471 3.37193 29.251 3.81147 28.8115C4.25101 28.3719 4.84715 28.125 5.46875 28.125H33.5938C34.2154 28.125 34.8115 28.3719 35.251 28.8115C35.6906 29.251 35.9375 29.8471 35.9375 30.4688C35.9375 31.0904 35.6906 31.6865 35.251 32.126C34.8115 32.5656 34.2154 32.8125 33.5938 32.8125Z" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                  <path d="M25 46.875H38.6045C39.3929 46.875 40.1521 46.577 40.73 46.0407C41.308 45.5045 41.6618 44.7696 41.7207 43.9834L45.2148 10.9375" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                  <path d="M35.9375 10.9375L37.5 4.6875L42.0898 3.125" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M21.875 10.9375H46.875" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                </svg>
              </div>
              <h3> Food & Beverages</h3>
              <p>Enjoy delicious in-theatre dining options at affordable prices!</p>
            </div>
            <div className="card">
              <div className="icon">
                <svg width="50" height="50" viewBox="0 0 50 50" fill="none">
                  <path d="M43.7363 9.375H6.26367C4.53023 9.375 3.125 10.7802 3.125 12.5137V32.7988C3.125 34.5323 4.53023 35.9375 6.26367 35.9375H43.7363C45.4698 35.9375 46.875 34.5323 46.875 32.7988V12.5137C46.875 10.7802 45.4698 9.375 43.7363 9.375Z" stroke="black" stroke-linejoin="round" />
                  <path d="M12.5 40.625H37.5Z" fill="black" />
                  <path d="M12.5 40.625H37.5" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                </svg>
              </div>
              <h3>Screening</h3>
              <p>Bring your own OTT accounts and relive the theatre magic!</p>
            </div>
            <div className="card">
              <div className="icon">
                <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
                  <path d="M25.593 11.1434V16.6121M25.593 11.1434C25.593 10.0617 25.9138 9.00442 26.5147 8.10509C27.1156 7.20576 27.9697 6.50482 28.969 6.0909C29.9683 5.67698 31.0678 5.56868 32.1287 5.7797C33.1895 5.99071 34.1639 6.51156 34.9288 7.27638C35.6936 8.0412 36.2144 9.01563 36.4254 10.0765C36.6365 11.1373 36.5282 12.2369 36.1142 13.2362C35.7003 14.2354 34.9994 15.0896 34.1 15.6905C33.2007 16.2914 32.1434 16.6121 31.0618 16.6121H25.593M25.593 11.1434C25.593 10.0617 25.2723 9.00442 24.6714 8.10509C24.0705 7.20576 23.2164 6.50482 22.2171 6.0909C21.2178 5.67698 20.1182 5.56868 19.0574 5.7797C17.9965 5.99071 17.0221 6.51156 16.2573 7.27638C15.4925 8.0412 14.9716 9.01563 14.7606 10.0765C14.5496 11.1373 14.6579 12.2369 15.0718 13.2362C15.4857 14.2354 16.1867 15.0896 17.086 15.6905C17.9853 16.2914 19.0427 16.6121 20.1243 16.6121H25.593" stroke="black" stroke-miterlimit="10" stroke-linecap="round" />
                  <path d="M41.218 16.6121H9.96802C8.24213 16.6121 6.84302 18.0112 6.84302 19.7371V24.4246C6.84302 26.1505 8.24213 27.5496 9.96802 27.5496H41.218C42.9439 27.5496 44.343 26.1505 44.343 24.4246V19.7371C44.343 18.0112 42.9439 16.6121 41.218 16.6121Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M41.218 27.5496V41.6121C41.218 42.8553 40.7242 44.0476 39.8451 44.9267C38.966 45.8058 37.7737 46.2996 36.5305 46.2996H14.6555C13.4123 46.2996 12.22 45.8058 11.341 44.9267C10.4619 44.0476 9.96802 42.8553 9.96802 41.6121V27.5496M25.593 16.6121V46.2996" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <h3>Cakes</h3>
              <p>Choose the perfect cake for your celebration from our selection. </p>
            </div>
            <div className="card">
              <div className="icon">
                <svg width="34" height="46" viewBox="0 0 34 46" fill="none">
                  <path d="M32.625 10.5C30.7793 10.915 29.0312 11.373 27.3809 11.8623C23.4258 13.0342 20.0078 14.5137 17.0879 15.9297C15.2031 16.8447 14.5293 17.249 12.0586 18.6865C6.35547 21.9766 2.9375 26.2451 2.9375 32.6328C2.9375 39.8506 8.36719 44.875 17 44.875C25.6328 44.875 31.0625 39.4248 31.0625 32.207C31.0625 24.9893 25.1055 19.9961 32.625 10.5Z" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M17.7812 15.5967C15.8584 12.96 14.0508 11.8184 12.6348 11.0469C7.87891 8.45898 1.375 7.375 1.375 7.375C5.42773 11.6426 5.00781 16.1738 4.5 19.875C4.5 19.875 4.12207 23.0859 4.68652 25.5791" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M28.3281 11.5889C26.8193 8.01953 24.8125 4.25 24.8125 4.25C24.8125 4.25 18.6289 4.25 11.5615 10.5M16.7539 6.55176C13.6064 2.47363 9.1875 1.125 9.1875 1.125C7.69336 3.15625 6.37598 6.16211 5.78223 8.36816" stroke="black" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </div>
              <h3>Bouquets</h3>
              <p>Add a beautiful rose bouquet to enhance your celebration.</p>
            </div>
          </div>
        </div>
        <div className="location">
          <p>Now At Your Convenient Location </p>
          <h1>Kompally</h1>
          <Link to={'/book-slot'} className="hero-button">Book Now</Link>
          <div className="img_map">
            <iframe title="Location" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d121799.20198575634!2d78.3201932!3d17.4489388!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb85b02e758e5f%3A0x4767a86afd8a845a!2sGame%20Theory%20Cafe%2C%20Gaming%20Zone%20%26%20Private%20Theatres!5e0!3m2!1sen!2sin!4v1701426053952!5m2!1sen!2sin"
              allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </>
  )
}
export default Gtmovie;