import React from 'react'
import Navbar from './home/Navbar'
import Footer from './Footer'
import ScrollToTop from './ScrollToTop'

function Layout({ children }) {
    return (
        <>
            <ScrollToTop />
            <Navbar />
            <div className="layout">
                {children}
            </div>
            <Footer />
        </>
    )
}

export default Layout