import React from 'react';
import "../../styles/moviehome.css"


const MovieHome = () => {
    const movieSections = [
        {
            imageUrl: 'https://st.hzcdn.com/simgs/pictures/home-theaters/theatre-3-henry-s-audio-visual-solutions-dba-audio-images-img~8ee1c777075ee4e0_16-2268-1-20c33b1.jpg',
            title: 'Private Spaces for Event Celebration',
            description: 'Birthday celebrations or a special someone’s anniversary like a Parents Wedding anniversary celebrations. Show them your appreciation and love for them. Birthday celebrations can involve cakes, candles, balloons, gifts, cards, and parties. to celebrate years together. These are events where couples commemorate their marriage and reaffirm their love and commitment to each other. Wedding anniversary celebrations can involve flowers, chocolates, jewelry, cards, and romantic dinners The vacant dining table was now covered with trays of spaghetti, fruit tarts, colourful jellies, and crispy chips and delicious pastries. Everyone started singing as my mother brought out a huge chocolate-vanilla cake. I was overjoyed with so much love and happiness. We ended up playing many birthday party games—musical chairs, pass the parcel, newspaper dance. My friend won 2 games and won chocolates.',
        },
        {
            imageUrl: 'https://imageio.forbes.com/specials-images/imageserve/5e9c698d7be2870006ce2a1d/0x0.jpg?format=jpg&crop=4589,2582,x701,y0,safe&height=900&width=1600&fit=bounds',
            title: 'Top 10 Inspirational Movies on OTT',
            description: 'Inspirational movies are a genre of films that aim to uplift, motivate, and inspire viewers. These movies often tell stories of triumph over adversity, personal growth, and the indomitable human spirit. They can explore various themes such as overcoming obstacles, pursuing dreams, finding inner strength, and making a positive impact on others and the world. Inspirational movies often feature compelling characters who face challenges, setbacks, or difficult circumstances, but ultimately find the courage, determination, and resilience to overcome them. These films have the power to evoke strong emotions, ignite hope, and leave a lasting impact, reminding us of the potential for greatness within ourselves and inspiring us to embrace our own journeys of self-discovery and personal fulfillment...',
        },
        {
            imageUrl: 'https://cdn.architecturendesign.net/wp-content/uploads/2014/08/1214.jpg',
            title: 'Top 10 Romance Movies on OTT',
            description: 'Today, romance is one of the most popular genres to watch on television. Unlike most, romance is a genre where the plot revolves around the love between two main characters as they experience the highs and lows of love. “Common themes that revolve around romantic movies are kissing, love at first sight, tragic love, destructive love, and sentimental love” (Taylor). These themes appear in many historical films and the pattern still continues in modern films as well. Watching romantic movies has a giant negative influence on the viewers analysis of what love and relationships should really be like. These films give the wrong impression of reality when it comes to dating, marriage, having children, and even how to manage a relationship',
        },
        {
            imageUrl: 'https://st.hzcdn.com/simgs/pictures/home-theaters/private-cozy-cinema-the-onetouch-house-inc-img~51f181be0463b18a_4-7711-1-04c43c5.jpg',
            title: 'Top 10 Science Fiction Movies on OTT',
            description: 'Science fiction movies are a genre of film that explores imaginative and speculative concepts, often set in futuristic or alternative worlds. These movies frequently incorporate elements of advanced technology, space exploration, time travel, extraterrestrial life, dystopian societies, or other scientific and technological advancements. In this blog we have covered 10 best films available on OTT platforms These movies typically explore various aspects of romance, such as passion, longing, courtship, heartbreak, and the complexities of human emotions. Romantic films often involve a central love story or a romantic subplot, and they can be set in various time periods, genres, and settings. From heartwarming comedies to sweeping epics and tender dramas, romantic movies aim to evoke emotions, captivate audiences, and celebrate the power of love....',
        }
    ]

    return (
        <section className="main-home movie-home">
            <div className="font_hd txt-center">
                <p>Watch a Movie | Have Some Cake | Celebrate | Hangout</p>
            </div>
            <div className="row-multicolums">
                {movieSections.map((section, index) => (
                <div key={index} className="row-cols">
                    <img src={section.imageUrl} className="img_movie" alt="banner" />
                    <h3 className="movie_para">{section.title}</h3>
                    <p className="para-main">{section.description}</p>
                    <button className="btns margin_btm">Read More...</button>
                </div>
                ))}
            </div>
        </section>
    )
}

export default MovieHome