import "./App.css"
import Layout from "./components/Layout";
import GTRoutes from './components/Routes'

function App() {
  return (
    <>
      <div className="App">
        <GTRoutes />
      </div>
    </>
  );
}

export default App;