import React from "react"
import "../styles/category.css"
import "../assets/c1.png"

const Category = () => {
    return (
        <>
        <div className="margin_tp">
            <main className="board-game margin_tp">
            <div>
                <span>
                <h1 className="font_hd">
                    <h3>Choose Your Favorite <span>Board Game</span></h3>
                </h1>
                </span>
                <span>
                <p>
                    <h2>Jenga, Chess, Monopoly, UNO <span>& many more</span></h2>
                </p>
                </span>
                <span>
                <p>Do these words strike a chord? Whether you’re a competitive player or you’re just in it for fun, board games can be therapeutic and exciting. And growing up doesn’t mean you need to stop having fun. So, feel like a child again at the Game Theory over some yummy snacks.</p>
                </span>
                <span>
                    <button className="btns">Get Started...</button>&nbsp; &nbsp;&nbsp; &nbsp;
                </span>
            </div>
            <div>
                <img src={require("../assets/board-games.jpg")} className="image_home" alt="banner" />
            </div>
            </main>
        </div>
        </>
    )
}

export default Category