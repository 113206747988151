import Category from "./Category"
import BoardGameType from "./BoardGameType"
import GamingTheme from "../pages/GamingTheme"
import Banner from "./home/Banner"
import MovieHome from "./home/MovieHome"
import { Helmet } from "react-helmet"

function HomeScreen() {
    return (
        <div className="home">
            <Helmet htmlAttributes>
                <html lang="en" />
                <title>Cafe Game Theory - Food, Coffee, Private Movie Theatres, PC & PS5 Gaming Zone in Kompally, Hyderabad</title>
                <meta name="og:description" content="Game Theory provides top-rated private theatres for celebrations and movie screening in Secunderabad and Kompally. Celebrate birthdays, anniversaries, dates, and proposals in our best-in-class theatres equipped with Dolby Atmos sound, 4k video, and Apple TV. Get ready to experience Game Theory at the most trusted private theatres. Reserve now and create unforgettable moments with your loved ones!" />
            </Helmet>
            <Banner />
            <Category />
            <MovieHome />
            <BoardGameType />
            <GamingTheme />
        </div>
    )
}

export default HomeScreen