import React from "react";
import "../styles/contact.css";
import Navbar from "../components/home/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const ContactForm = () => {
  return (
    <>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>Contact Us - Cafe Game Theory, Kompally, Hyderabad</title>
        <meta name="og:description" content="Contact Game Theory for Booing or reserving Hyderabad's top-rated private theatres for celebrations Reserve Your Space now and Vibe in Style. 24x7." />
      </Helmet>
      <div className="contact containers">
        <div className="form-txt">
          <div className="cont_main">
            <h1>Contact Us</h1><br />
            <h2>Address:</h2><br />
            <p>Cafe Game Theory</p>
            <p>Plot 162/P, Jayabheri Park Road, Near Cine Planet, Kompally</p>
            <p>Hyderabad, Telangana - 500014</p>
            <p>Phone: <a href="tel:+917799420720">+91 7799 420 720</a></p>
            <p>Email: <a href="mailto:book@gametheory.cafe">book@gametheory.cafe</a></p>
          </div>
        </div>
        <div className="img_mapp">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d121799.20198575634!2d78.3201932!3d17.4489388!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb85b02e758e5f%3A0x4767a86afd8a845a!2sGame%20Theory%20Cafe%2C%20Gaming%20Zone%20%26%20Private%20Theatres!5e0!3m2!1sen!2sin!4v1701426053952!5m2!1sen!2sin" width="850" height="550" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className="img_home"></iframe>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
